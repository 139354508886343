import { Container } from "react-bootstrap";
import "./Footer.scss";
import logo from "../../../assets/logo/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <div className="footer_in">
                    <Link
                        to="/"
                        className="logo"
                    >
                        <img src={logo} alt="logo" />
                    </Link>
                    <div className="copyright">
                        <p>© Collatix {new Date().getFullYear()}</p>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
