import { Col, Container, Row } from "react-bootstrap";
import "./Features.scss";
import lending from "../../../../assets/icons/transparent-lending.png";
import borrowers from "../../../../assets/icons/borrowers.png";
import focus from "../../../../assets/icons/focus.png";
import { AOS } from "../../../../utils/utils";

const Features = () => {
    const features = [
        {
            icon: lending,
            title: "Transparent Lending",
            description: "Complete transparency in showcasing every loan, its term, and its current status empowers lenders to make well-informed capital allocation decisions.",
        },
        {
            icon: focus,
            title: "Institutional Focus",
            description: "OPM collaborates with accredited service providers and esteemed institutions, specializing in delivering custody and wallet solutions, as well as conducting thorough KYC and AML verifications for both Lenders and Borrowers, with a strong focus on institutions.",
        },
        {
            icon: borrowers,
            title: "Designed for Lenders & Borrowers",
            description: "Getting started, making deposits, and managing positions is effortless. Our team is at your service round the clock to assist you.",
        },
    ]
    return (
        <section className="features">
            <Container>
                <div className="features_in">
                    <h2  {...AOS()}>Our <span>Features</span></h2>
                    <p {...AOS({ delay: 100 })} className="sub_text">We offer a variety of interesting features that you can help increase your productivity.</p>
                    <Row className="justify-content-center">
                        {
                            features.map((item, index) => {
                                return (
                                    <Col key={item.title} lg={4} sm={6}>
                                        <div  {...AOS({ delay: index * 150 })} className="feature_box">
                                            <img src={item.icon} alt={item.title} />
                                            <h4>{item.title}</h4>
                                            <p>{item.description}</p>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Features
