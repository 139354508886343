import { Container } from "react-bootstrap";
import offering from "../../../../assets/images/offering.png";
import "./Offering.scss";
import { BorrowIcon, LendingIcon } from "../../../../assets/icons/icons";
import { AOS } from "../../../../utils/utils";

const Offering = () => {
    return (
        <section className="offering">
            <Container>
                <div className="offering_in">
                    <div {...AOS()} className="offering_graphic">
                        <img src={offering} alt="offering" />
                    </div>
                    <div className="offering_text">
                        <h2  {...AOS()} >What we are <span>Offering</span></h2>
                        <p   {...AOS({ delay: 100 })} className="sub_text">Getting started, making deposits, and managing positions is effortless. Our team is at your service round the clock to assist you.</p>
                        <ul>
                            <li  {...AOS({ delay: 150 })}>
                                <div className="offering_item">
                                    <div className="offering_item_icon"><LendingIcon /></div>
                                    <div className="offering_item_text">
                                        <h4>Lend</h4>
                                        <p>Deposits your token into the protocol and learn rewards for securing the protocol.</p>
                                    </div>
                                </div>
                            </li>
                            <li  {...AOS({ delay: 200 })}>
                                <div className="offering_item">
                                    <div className="offering_item_icon"><BorrowIcon /></div>
                                    <div className="offering_item_text">
                                        <h4>Borrow</h4>
                                        <p>Borrow against your collateral from across multiple networks and assets.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Offering
