import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo/logo.png";
import "./Header.scss";
import Button from "../Button/Button";

const Header = () => {
    return (
        <header className="header">
            <Container>
                <div
                    className="header_in"
                >
                    <Link
                        to={"/"}
                        className="logo"
                    >
                        <img src={logo} alt="logo" />
                    </Link>
                    <Button className="ms-auto">Launching Soon</Button>
                </div>
            </Container>
        </header>
    )
}

export default Header
