import { Outlet } from "react-router-dom";
import Header from "../../common/Header/Header";
import "./Layout.scss";
import Footer from "../../common/Footer/Footer";

const Layout = () => {
    return (
        <main className="layout">
            <Header />
            <Outlet />
            <Footer />
        </main>
    )
}

export default Layout
