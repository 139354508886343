import { ButtonHTMLAttributes } from "react";
import "./Button.scss";

type propTypes = ButtonHTMLAttributes<HTMLButtonElement> & {
    fluid?: string,
}

const Button = ({ fluid, className, children, ...rest }: propTypes) => {
    return (
        <button
            type="button"
            {...rest}
            className={`${className || ""} ${fluid ? "w-100" : ""} custom_btn`}
        >
            {children}
        </button>
    )
}

export default Button
