import { Container } from "react-bootstrap";
import "./Banner.scss";
import { AOS } from "../../../../utils/utils";

const Banner = () => {
    return (
        <section className="banner">
            <Container>
                <div
                    className="banner_in"
                    {...AOS({ delay: 30, duration: 200, })}
                >
                    <div className="banner_box">
                        <h1
                            {...AOS({ delay: 100 })}
                        >
                            Collatix <span>Liquidity</span> Protocol
                        </h1>
                        <p
                            {...AOS({ delay: 150, duration: 500, offset: -100 })}
                        >Earn interest, borrow assets, and build applications</p>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Banner
