import { Col, Container, Row } from "react-bootstrap";
import "./About.scss";
import why from "../../../../assets/images/why.png";
import leverage from "../../../../assets/images/leverage.png";
import { AOS } from "../../../../utils/utils";

const About = () => {
    return (
        <section className="about">
            <Container>
                <Row>
                    <Col md={6}>
                        <div  {...AOS()} className="about_box">
                            <img className="about_box_icon" src={why} alt="Why use Collatix?" />
                            <h3>Why use <span>Collatix?</span></h3>
                            <p><span>Your funds, your choice:</span> put your capital to work while staying in full control, with no exceptions</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div  {...AOS({ delay: 100 })} className="about_box">
                            <img className="about_box_icon leverage" src={leverage} alt="Leverage Collatix to amplify your returns " />
                            <h3>Leverage <span>Collatix</span> to amplify your returns </h3>
                            <p>Collatix and the xTokens reward dedicated users of Collatix Protocol with real yield for superior rates with their lending and borrowing activities.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default About
