import About from "./About/About";
import Banner from "./Banner/Banner";
import Features from "./Features/Features";
import "./Homepage.scss";
import Offering from "./Offering/Offering";
import Work from "./Work/Work";

const Homepage = () => {
    return (
        <>
            <Banner />
            <Offering />
            <Features />
            <Work />
            <About />
        </>
    )
}

export default Homepage
