import { Container } from "react-bootstrap";
import "./Work.scss";
import { BorrowIcon, DepositIcon, WalletIcon } from "../../../../assets/icons/icons";
import { AOS } from "../../../../utils/utils";

const Work = () => {
    const work = [
        {
            icon: <WalletIcon />,
            title: "Step 1",
            description: "Connect wallet to Collatix Secure custody for XFI and xTokens, and native support for Collatix governance.",
        },
        {
            icon: <DepositIcon />,
            title: "Step 2",
            description: "Deposit XFI as collateral and Increase Borrow Limit",
        },
        {
            icon: <BorrowIcon />,
            title: "Step 3",
            description: "Borrow in Stable coins while enjoying the appreciation of your asset",
        },
    ]
    return (
        <section className='work'>
            <Container>
                <h2  {...AOS()}>How it <span>Works</span></h2>
                <div className="work_in">
                    {
                        work.map((item, index, thisAr) => {
                            return (
                                <div  {...AOS({ delay: index * 150 })} key={item.title} className="work_box">
                                    {index !== thisAr.length - 1 && <span className="line" />}
                                    <div className="work_box_icon">
                                        {item.icon}
                                    </div>
                                    <div className="work_box_content">
                                        <p>{item.title}</p>
                                        <h4>{item.description}</h4>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Container>
        </section>
    )
}

export default Work
