import Lenis from 'lenis';
import AOS from "aos";
import { useEffect } from 'react';
import Application from './Application';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: "ease-in-out",
      disable: "mobile",
    })

    const lenis = new Lenis()

    function raf(time: number) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
  }, [])
  return (
    <Application />
  )
}

export default App
